import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';

import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import * as Styled from './styles';

const Lessons = () => {
  const { mdx, allMdx } = useStaticQuery(graphql`
    query {
      mdx(frontmatter: { category: { eq: "lesson curriculum" } }) {
        frontmatter {
          title
          subtitle
          description
        }
      }
      allMdx(
        filter: { frontmatter: { category: { eq: "lesson section" } } }
        sort: { fields: frontmatter___sequence, order: ASC }
      ) {
        edges {
          node {
            id
            body
            fields {
              slug
            }
            frontmatter {
              title
              subtitle
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 240) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const sectionTitle = mdx.frontmatter;
  const lessons = allMdx.edges;

  return (
    <Container section>
      <SEO title={sectionTitle.title} description={sectionTitle.description} />
      <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />
      <Styled.Lessons>
        {lessons.map((item) => {
          const {
            id,
            fields: { slug },
            frontmatter: { title, subtitle, image }
          } = item.node;

          return (
            <Styled.Lesson key={id}>
              <Link to={slug}>
                <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                  <Styled.Card>
                    {image && (
                      <Styled.Image>
                        <Img fluid={image.childImageSharp.fluid} alt={title} />
                      </Styled.Image>
                    )}
                    <Styled.Content>
                      <Styled.Title>{title}</Styled.Title>
                      <Styled.Subtitle>{subtitle}</Styled.Subtitle>
                    </Styled.Content>
                  </Styled.Card>
                </motion.div>
              </Link>
            </Styled.Lesson>
          );
        })}
      </Styled.Lessons>
    </Container>
  );
};

export default Lessons;
