import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Lessons from 'components/Lessons';

const LessonPage = () => {
  return (
    <Layout>
      <SEO title="Belajar Coding" />
      <Lessons />
    </Layout>
  );
};

export default LessonPage;
